import React from "react"
import { Link } from "gatsby"

export default () => (
  <div>
      <h1 class="title">あおまるの人物像</h1>
      <Link to="/">Homeへ</Link>
      <div class="qualification">
        <h3>資格</h3>
        <h4>中型(8t限定)自動車免許／普通自動二輪車免許／大型自動二輪車免許</h4>
        <h4>基本情報処理技術者試験／AWS Certified Cloud Practitioner／AWS Certified Solutions Architect – Associate</h4>
      </div>
      <div class="work">
        <h2>お仕事</h2>
        <h3>webシステムDevOps</h3>
        <p class="maintxt">
          AWS上のwebシステムに対するDevOps（主にOps）業務に10ヶ月従事。
          shell + cron のバックアップ処理をTerraformでAWS Backup + Lambda(Go) + Amazon SNS というマネージドかつIaCな構成にリプレースする業務や
          Java(SpringFramework)アプリケーション(with Elastic Beanstalk)の改修、インシデント対応に従事。
        </p>
        <h3>ネイティブアプリ</h3>
        <p class="maintxt">
          株価チャートソフトウェアのリファクタリング、機能追加開発。主に.NET, WPFとＣ＃を使用し約４年間従事。
          Windows用ソフトウェアTV会議システムの通話画面や、各種VoIP関連アプリの開発を担当。主にWindows SDK, .NETとC++を約４年間使用。
        </p>
        <h3>プロトコルスタック</h3>
        <p class="maintxt">
          接続性向上のための他社プロトコルスタックの技術調査、RFC対応の自社SIP Proxyスタックの改修、SIP-NAT GW開発を担当。主にC++を使用し約１年間従事。
        </p>
        <h3>LAMP API開発</h3>
        <p class="maintxt">
          ネイティブアプリ ー オンプレサーバ間APIの開発、運用、保守を担当。PHP5,7を使用し約５年間従事。
        </p>
        <h3>株価データ解析システム</h3>
        <p class="maintxt">
          オシレータ系・トレンド系指標プログラム機能開発、運用、保守を担当。Java5,6を使用し約５年間従事。
          研究員と連携し、売買タイミングの自動判定システム設計、開発を担当。Java7で約２年間従事。
        </p>
        <h3>ファンダメンタル情報webサイト</h3>
        <p class="maintxt">
          ネイティブアプリ内専用サイトのDBアクセス設計、開発、運用、保守を担当。PHP5,7で約４年従事。
          同サイトのUI保守、機能改善を担当。PHP, Backbone.js, Bootstrap, jQueryで約３ヶ月従事
        </p>
        <h3>株価データ解析ツール</h3>
        <p class="maintxt">
          社内の投資アドバイザー要望に応える各種株価データ関連ツールを仕様ヒアリングから開発まで担当。
          スクレイピング、UI、PDF解析などをPHP5,7またはJava5,6を使用し約１年間従事。
        </p>
        <h3>特許請求文書作成</h3>
        <p class="maintxt">
          発案者ヒアリングから請求項原文作成まで担当し、１件特許化済。請求項原文作成と実現（実装）を担当。審査請求中。
        </p>
        <h3>HPCサーバシステム製造・保守</h3>
        <p class="maintxt">
          サーバシステムの受託製造。サーバ組み立て、ラック調整、ラックマウントまでを担当し半年従事。
          出荷製品の保守業務。ハードウェア交換、メンテナンス、部材管理を担当し約１年間従事。
        </p>
      </div>
      <div class="study">
        <h2>勉強中</h2>
        {/* <p class="maintxt">
          AWSに全く触れた事がなく強い危機感を感じて開始。CloudFront + Route53 + S3の環境を作りました。
          フロントも少しは知っておかないとと思い、React全く分からんのにGatsbyでサイト生成して、今調べながらこれを書いています。
          それでも３日でここまで作れた事自体がGatsbyの爆速生成とAWSの低い導入コストの証左かなと思います。
        </p> */}
        <h3>スマートホームアプリ（停止中）</h3>
        <p class="maintxt">
          スマートホームアプリ作成を通じてJavascriptとNode.jsを習得中。今の所はGoogleAssistantを想定して
          Actions on Google + DialogFlow + Firebase Functionsの構成。
        </p>
        <h3>Docker</h3>
        <p class="maintxt">
          仕事でDockerComposeに触れてはいたもののスクラッチは未経験。
          Minecraftのプライベートサーバ環境をTerraformで構築したものの、EC2＋手動アプリケーションインストールなので
          ECS on Fargate化しつつCI/CD構築を予定。
        </p>
        {/* <p class="maintxt">
          スマートホームアプリ作成の途中から知り、構成をコピーしてコンテナ化してみたものの利便性を実感できず。
          Gatsby環境(このサイト)を作る時に最初からコンテナ化してやっと実感。ローカルのライブラリ環境が他プロジェクトに引っ張られないし
          これ複数人で作業するときも絶対楽だと思う。
        </p> */}
        <h3>Kubernetes</h3>
        <p class="maintxt">
          会社でマイクロサービス化を推進していたものの、個人的にほぼ関与できなかったため勉強したい。
          簡単なアプリケーションをECSまたはEKS＋Fargateに展開することでDockerと同時に勉強できそうだなぁと雑に計画中。
        </p>
      </div>
  </div>
)
